import SHA256 from 'crypto-js/sha256';
import { MerkleTree } from 'merkletreejs';

export const createMerkleRoot = (wallets) => {
    const leaves = wallets.map(x => x.replace("0x", "0x000000000000000000000000"));
    const tree = new MerkleTree(leaves, SHA256, { sortPairs: true });
    const root = tree.getHexRoot();

    return root;
}


