import React, { useState } from "react";
import Editor from "./components/Editor";
import { createMerkleRoot } from "./helpers/merkle";
import { CopyToClipboard } from "react-copy-to-clipboard";


function App() {
  const [html, setHtml] = useState("");
  const [root, setRoot] = useState("");
  const [formatedAddress, setFormatedAddress] = useState("");

  const formatAddress = () => {
    const addresssToArray = html.split("\n");

    const uniques = [...new Set(addresssToArray)];

    let addressToLowerCase = [];
    for (const item of uniques) {
      addressToLowerCase.push(item.toLowerCase());
    }

    const getRoot = createMerkleRoot(addressToLowerCase);
    setRoot(getRoot);
    setFormatedAddress(JSON.stringify(addressToLowerCase, null, 4));
  };

  return (
    <div className="App" style={{padding: "5rem"}}>
      <div className="editor-container">
        <Editor
          language="xml"
          displayName="HTML"
          value={html}
          setEditorState={setHtml}
        />
      </div>
      <div>
        <button onClick={() => formatAddress()}>Get Root & Formated Address</button>
      </div>
      {root && <p><strong>Root:</strong> {root}</p>}
      {formatedAddress && (
        <>
          <CopyToClipboard text={formatedAddress}>
            <button>Copy all formated wallets</button>
          </CopyToClipboard>
          <pre>{formatedAddress}</pre>
        </>
      )}
    </div>
  );
}

export default App;
